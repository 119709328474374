const classNames = [
	'carrerum-nav-with-points',
	'carrerum-nav',
	'carrerum-nav-mob',
	'carrerum-site-header',
	'carrerum-nav3-mobile',
	'carrerum-nav3-tablet',
	'carrerum-main-with-button',
	'carrerum-main-with-button-mob',
	'carrerum-main-with-button2',
	'carrerum-main-with-button2-mobile',
	'carrerum-main-with-button2-tablet',
	'carrerum-main-with-button3',
	'carrerum-main-with-button3-mobile',
	'carrerum-main-with-button3-tablet',

	'carrerum-course-for-you-image',
	'carrerum-course-for-you-image-mob',
	'carrerum-at-course-you',
	'carrerum-at-course-you-mob',
	'carrerum-title-with-columns',
	'carrerum-with-us',
	'carrerum-with-us-mobile',
	'carrerum-with-us-tablet',

	'careerum-nav-with-region',
	'careerum-nav-with-region-tablet',
	'careerum-nav-with-region-mobile',

	'careerum-full-width-img',
	'careerum-full-width-img-tablet',
	'careerum-full-width-img-mobile',

	'carrerum-education-networking',
	'carrerum-education-networking-tablet',
	'carrerum-education-networking-mobile',

	'carrerum-club-tracks',
	'carrerum-club-tracks-tablet',
	'carrerum-club-tracks-mobile',

	'carrerum-curators',
	'carrerum-curators-tablet',
	'carrerum-curators-mobile',

	'carrerum-experts',
	'carrerum-experts-tablet',
	'carrerum-experts-mobile',

	'carrerum-coaches',
	'carrerum-coaches-tablet',
	'carrerum-coaches-mobile',

	'carrerum-events',
	'carrerum-events-tablet',
	'carrerum-events-mobile',

	'carrerum-offers',
	'carrerum-offers-tablet',
	'carrerum-offers-mobile',

	'carrerum-text-columns',
	'carrerum-text-columns-tablet',
	'carrerum-text-columns-mobile',
	
	'carrerum-faq2',
	'carrerum-faq2-tablet',
	'carrerum-faq2-mobile',
	
	'carrerum-try-start',
	'carrerum-try-start-tablet',
	'carrerum-try-start-mobile',

	'carrerum-question-with-social',
	'carrerum-question-with-social-tablet',
	'carrerum-question-with-social-mobile',
	
	'carrerum-popup-track',
	'carrerum-popup-track-tablet',
	'carrerum-popup-track-mobile',

	'carrerum-new-side',
	'careerum-new-side-mobile',
	'careerum-new-side-tablet',
	'carrerum-our-products',
	'carrerum-our-products-mobile',
	'carrerum-our-products-tablet',
	'carrerum-study-slider',		
	'carrerum-study-slider-mobile',		
	'carrerum-study-slider-tablet',		
	'carrerum-free-materials',
	'carrerum-free-materials-mobile',
	'carrerum-free-materials-tablet',
	'carrerum-centralized-image',
	'carrerum-centralized-image-mob',
	'carrerum-how-it-works',
	'carrerum-how-it-works-mob',
	'carrerum-club',
	'carrerum-club-mob',
	'carrerum-author-and-speaker',
	'carrerum-author-and-speaker-mob',
	'carrerum-course-author',
	'carrerum-course-author-mobile',
	'carrerum-course-author-tablet',
	'carrerum-join',
	'carrerum-join-mobile',
	'carrerum-join-tablet',
	'carrerum-review-slider',
	'carrerum-review-slider-mob',
	'carrerum-review-slider-mobile',
	'carrerum-review-slider-tablet',
	'carrerum-tarifs',
	'carrerum-tarifs-mob',
	'carrerum-faq',
	'carrerum-faq-mob',
	'carrerum-footer',
	'carrerum-footer-mob',
	'carrerum-footer-mobile',
	'carrerum-footer-tablet',
	'carrerum-site-footer',
	'carrerum-popup',
	'carrerum-popup-overlay',

	'carrerum-popup-enter-auth',
	'carrerum-popup-enter-auth-mobile',
	'carrerum-popup-enter-auth-tablet',

	'carrerum-popup-subscribe',
	'carrerum-popup-subscribe-mobile',
	'carrerum-popup-about',
	'carrerum-popup-about-mobile',
	'carrerum-popup-about-tablet',
	'carrerum-popup-form',
	'carrerum-popup-form-mobile',
	'carrerum-popup-form-tablet',

	'carrerum-button',

	'careerum-tiles-filter',
	'careerum-tiles-filter-tablet',
	'careerum-tiles-filter-mobile',


	/* TAPLINK */
			
	'carrerum-subscribe-text',
	'carrerum-subscribe-text-tablet',
	'carrerum-subscribe-text-mobile',
			
	'carrerum-about-author-with-publications',
	'carrerum-about-author-with-publications-tablet',
	'carrerum-about-author-with-publications-mobile',
			
	'carrerum-text-with-three-buttons',
	'carrerum-text-with-three-buttons-tablet',
	'carrerum-text-with-three-buttons-mobile',
			
	'carrerum-clients-top-companies',
	'carrerum-clients-top-companies-tablet',
	'carrerum-clients-top-companies-mobile',
			
	'carrerum-how-to-learn',
	'carrerum-how-to-learn-tablet',
	'carrerum-how-to-learn-mobile',
			
	'carrerum-text-in-frame',
	'carrerum-text-in-frame-tablet',
	'carrerum-text-in-frame-mobile',
			
	'carrerum-about-rayan',
	'carrerum-about-rayan-tablet',
	'carrerum-about-rayan-mobile',
			
	'carrerum-watch-read',
	'carrerum-watch-read-tablet',
	'carrerum-watch-read-mobile',
			
	'carrerum-slider-with-links-buttons',
	'carrerum-slider-with-links-buttons-tablet',
	'carrerum-slider-with-links-buttons-mobile',

	'carrerum-letters-slider-mobile',


	/* START */

	'carrerum-columns-text-highlight',
	'carrerum-columns-text-highlight-tablet',
	'carrerum-columns-text-highlight-mobile',

	'carrerum-1st-banner-with-sections',
	'carrerum-1st-banner-with-sections-tablet',
	'carrerum-1st-banner-with-sections-mobile',

	'carrerum-course-content',
	'carrerum-course-content-tablet',
	'carrerum-course-content-mobile',


	/* INTERVIEW */
			
	'carrerum-tarifs-with-points',
	'carrerum-tarifs-with-points-tablet',
	'carrerum-tarifs-with-points-mobile',


	/* CASE */
			
	'carrerum-member-results',
	'carrerum-member-results-tablet',
	'carrerum-member-results-mobile',
			
	'carrerum-case-header',
	'carrerum-case-header-tablet',
	'carrerum-case-header-mobile',
			
	'carrerum-case-point-a',
	'carrerum-case-point-a-tablet',
	'carrerum-case-point-a-mobile',
			
	'carrerum-case-point-b',
	'carrerum-case-point-b-tablet',
	'carrerum-case-point-b-mobile',

	
	/* EXPERTS */
			
	'carrerum-experts-list',
	'carrerum-experts-list-tablet',
	'carrerum-experts-list-mobile',
			
	'carrerum-members-are-experts',
	'carrerum-members-are-experts-tablet',
	'carrerum-members-are-experts-mobile',

			
	/* COACH */
			
	'carrerum-review-a-b-slider',
	'carrerum-review-a-b-slider-tablet',
	'carrerum-review-a-b-slider-mobile',
			
	'carrerum-programs-slider',
	'carrerum-programs-slider-tablet',
	'carrerum-programs-slider-mobile',
			
	'carrerum-popup-program',
	'carrerum-popup-program-tablet',
	'carrerum-popup-program-mobile',
			
	'carrerum-img-slider',
	'carrerum-img-slider-tablet',
	'carrerum-img-slider-mobile',

	
	/* TEAM */
			
	'core-values-slider',
	'core-values-slider-tablet',
	'core-values-slider-mobile',
			
	'carrerum-our-team-all',
	'carrerum-our-team-all-tablet',
	'carrerum-our-team-all-mobile',
			
	'carrerum-vacancy',
	'carrerum-vacancy-tablet',
	'carrerum-vacancy-mobile',

	
	/* RYAN */
			
	'carrerum-choose-social-btns',
	'carrerum-choose-social-btns-tablet',
	'carrerum-choose-social-btns-mobile',
			
	'carrerum-filter-for-sliders',
	'carrerum-filter-for-sliders-tablet',
	'carrerum-filter-for-sliders-mobile',
			
	'carrerum-choose-social-btns2',
	'carrerum-choose-social-btns2-tablet',
	'carrerum-choose-social-btns2-mobile',
			

	/* UNIVERSAL */

	'block-careerum-redirect-timer',
	'block-careerum-redirect-timer-tablet',
	'block-careerum-redirect-timer-mobile',

	'carrerum-contact-with-button',
	'carrerum-contact-with-button-tablet',
	'carrerum-contact-with-button-mobile',

	'carrerum-tarifs-with-selector-10900',
	'carrerum-tarifs-with-selector-tablet-10900',
	'carrerum-tarifs-with-selector-mobile-10900',

	'carrerum-tarifs-with-selector-9900',
	'carrerum-tarifs-with-selector-tablet-9900',
	'carrerum-tarifs-with-selector-mobile-9900',

	'carrerum-tarif-one',
	'carrerum-tarif-one-tablet',
	'carrerum-tarif-one-mobile',

	'carrerum-tarif-two',
	'carrerum-tarif-two-tablet',
	'carrerum-tarif-two-mobile',

];

export default classNames;