import grapesjs from 'grapesjs';
import loadDevaRegistrationForm from './deva-registration-form';

export default grapesjs.plugins.add('gjs-blocks-deva-registration-form', async (editor: any, opts: any = {}) => {
  const config = {
    category: 'Forms',
    componentName: 'deva-registration-form',
    offerTraitName: 'offerID',
    ...opts
  };

  loadDevaRegistrationForm(editor, config);
});
