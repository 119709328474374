import React, { useCallback, useEffect } from 'react';

import './App.css';
import GrapesJSEditor from "./GrapesJSEditor";
import "grapesjs-blocks-basic";
import grapesJS_CustomCode from "grapesjs-custom-code";
import grapesJS_Tooltip from "grapesjs-tooltip";
import grapesJS_Tabs from "grapesjs-tabs";
import grapesJS_Typed from  "grapesjs-typed";
import "grapesjs-lory-slider";
import grapesJS_StyleGradient from "grapesjs-style-gradient";
import grapesJS_StyleFilter from  "grapesjs-style-filter";
import grapesJS_StyleBG from  "grapesjs-style-bg";
import grapesJS_deva_registration_form from "./plugins/deva-registration-form/deva-registration-form-plugin";
import grapesJS_Careerum_timer from "./plugins/careerum-timer/careerum-timer-plugin";
import grapesJS_Careerum_redirect_timer from "./plugins/careerum-redirect-timer/careerum-redirect-timer-plugin";
import grapesJS_Careerum_blocks from "./blocks/careerum/careerum-plugin";
import grapesJS_Careerum2_blocks from "./blocks/careerum2.0/careerum-plugin";
import grapesJS_Careerum_EN_blocks from "./blocks/careerum-en/careerum-plugin";
import grapesJS_Careerum4_blocks from "./blocks/careerum4.0/careerum-plugin";
// import grapesJS_DCM_blocks from "./blocks/dcm/dcm-plugin";
import 'grapesjs/dist/css/grapes.min.css';

import { useParams, useLocation} from "react-router-dom";
import { apiDomain, apiBase, xAPIHeaders, AssetsService, GET_STYLE_TEMPLATE_URL, PagesService, SessionService } from "./api";


enum NotificationTypes {
  Published = 'Published',
  Saved = 'Saved',
  HasUndo = 'HasUndo'
}

class TinyStore {
  constructor(public hostApp: any, public pageEditor: any, public publishSender: any) {}
}

const store = new TinyStore(null, null, null);

window.addEventListener("message", function(event: any) {
  if (document.location.hostname === `localhost` || event.origin === 'http://localhost:3002' || event.origin === 'http://deva-crm.herokuapp.com') {
    return;
  }

  const eventData: { type: string } = event.data === "" ? event.data : JSON.parse(event.data);

  switch (eventData.type) {
    case NotificationTypes.HasUndo: {
      notifyHost({ type: NotificationTypes.HasUndo, data: store.pageEditor.UndoManager.hasUndo() });
    }
  }

  store.hostApp = event.source;
});

const notifyHost = (data: { type: NotificationTypes, data?: any }) => {
  if (store.hostApp) {
    store.hostApp.postMessage(JSON.stringify(data), '*');
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App() {
  const { pageID } = useParams<{ pageID: any }>();
  const query = useQuery();
  const styleTemplateID = query.get("styleTemplateID");

  const getAssetsLists = () => {
    AssetsService.getAssetsList()
      .then((response: any) => {

        if (response.status === 200) {
          
          response.data.forEach((file: string) => {
            store.pageEditor.AssetManager.add(file);
          });

        } else {
          console.error('Assets - FetchError');
        }
      })
      .catch(() => {
        console.error('Assets - FetchError');
      });
  };

  const trackVisit = () => {
    SessionService.trackRequest(window.location.href, document.referrer);
  };

  const insertStyleTemplateToEditor = useCallback(() => {
    if (styleTemplateID) {
      const iframe = document.querySelector(".gjs-frame") as any;
      const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
      const iframeHead = iframeDocument.head;
      const linkEl = document.createElement('link');

      iframeHead.appendChild(linkEl);

      linkEl.type = 'text/css';
      linkEl.rel = 'stylesheet';
      linkEl.href = GET_STYLE_TEMPLATE_URL + styleTemplateID;
    }
  }, [styleTemplateID]);

  // Component Did Mount
  useEffect(() => {
    insertStyleTemplateToEditor();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publishPage = () => {
    PagesService.publishPage(pageID)
      .then((response: any) => {
        console.log("publish-page response: " + JSON.stringify(response));

        const statusCode = response.status;
        // response from server
        if (statusCode === 200) {
          console.log("GrapesJS publish successfully!");
          store.pageEditor.UndoManager.clear();

          notifyHost({ type: NotificationTypes.Published });
        } else {
          console.log("GrapesJS publish returned statusCode: " + statusCode);
        }

        store.publishSender && store.publishSender.set('active'); // turn off the button

        // clear 
        store.publishSender = null;
      })
      .catch((reason: any) => {
        store.publishSender = null;
        console.log(reason);
      });
  };

  return (
    <div className="App" id={ "pageID-" + pageID }>
      <GrapesJSEditor
        presetType="webpage"

        plugins={[
          grapesJS_Careerum4_blocks as string,
          grapesJS_Careerum_EN_blocks as string,
          grapesJS_Careerum2_blocks as string,
          grapesJS_Careerum_blocks as string,
          "gjs-blocks-basic", 
          grapesJS_CustomCode as string,
          grapesJS_deva_registration_form as string,
          grapesJS_Careerum_timer as string,
          grapesJS_Careerum_redirect_timer as string,
          // grapesJS_DCM_blocks as string,
          grapesJS_Tooltip as string, 
          grapesJS_Tabs as string, 
          grapesJS_Typed as string, 
          "grapesjs-lory-slider", 
          grapesJS_StyleGradient as string, 
          grapesJS_StyleFilter as string, 
          grapesJS_StyleBG as string
        ]}

        pluginsOpts={{ 
          [grapesJS_deva_registration_form as string]: {
            "pageID": pageID
          },
          "grapesjs-lory-slider": {
            "sliderBlock": {
              "category": "Extra"
            }
          },
          [grapesJS_Tabs as string]: {
            "tabsBlock": {
              "category": "Extra"
            }
          },
          [grapesJS_Typed as string]: {
            "block": {
              "category": "Extra",
              "content": {
                "type": "typed",
                "type-speed": "40",
                "strings": [
                  "Text row one",
                  "Text row two",
                  "Text row three"
                ]
              }
            }
          }
        }}

        protectedCss = {`* { box-sizing: border-box; } body {margin: 0;} [data-gjs-type=wrapper] { padding: 20px 0; } .deva-form input { margin-bottom: 15px; } [data-gjs-type="custom-code"] { min-height: 50px; } [data-gjs-type="custom-code"] > * { pointer-events: none; }`}

        assetManager={{
          uploadName: 'files',
          headers: xAPIHeaders,
          showUrlInput: false,
          uploadFile: function(e: any) {
            const files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
      
            let formData = new FormData();
            for (let i = 0; i < files.length; i++) {
              formData.append('files', files[i]);
            }

            AssetsService.uploadAssets(formData).then((result: any) => {
              if (result.status === 200) {

                result.data.forEach((file: string) => {
                  store.pageEditor.AssetManager.add(file);
                });

              }
            });
        
          }
        }}

        storageManager={{
          autosave: false,
          setStepsBeforeSave: 1,
          type: 'remote',
          urlLoad: apiBase + 'pages/' + pageID,
          urlStore: apiBase + 'pages/update/' + pageID,
          contentTypeJson: true,
          headers: xAPIHeaders
        }}

        onInit={(editor: any) => {
          store.pageEditor = editor;

          editor.BlockManager.getCategories().each((category: any) => category.set('open', false))

          /* SAVE to DB  functionality */
          const saveDBCommand = 'save-db';

          editor.Panels.addButton('options',
            [{
              id: 'save-db',
              className: 'fa fa-floppy-o',
              command: saveDBCommand,
              attributes: {title: 'Save DB'}
            }]
          );

          // Add the command
          editor.Commands.add(saveDBCommand, { 
              run: async function(editor: any, sender: any) {
                sender && sender.set && sender.set('active'); // turn off the button
                
                // save/update forms in DB
                await editor.runCommand("save-deva-forms");
                
                editor.store();
              }
          });

          let updatedComponents: string[] = [];

          editor.on('storage:load', function(e: any) { 
            console.log('Loaded ', e);

            // make next requests to API after cookies are set (automatically after page load)

            // get assets of editor
            getAssetsLists();
            // store visit to editor in DB
            trackVisit();

            // show popup if components were updated with newer version
            setTimeout(() => {
              if (updatedComponents.length > 0) {
                alert(`Компоненты "${updatedComponents.join(', ')}" автоматически обновлёны.\n\nНе забудьте переопубликовать страницу.`);
              }
            }, 10000);
          });
          editor.on('storage:store', function(e: any) {
            console.log('Stored ', e);
            notifyHost({ type: NotificationTypes.Saved });
            editor.UndoManager.clear();

            if (store.publishSender) {
              publishPage();
            }
          });
          editor.on('storage:error', (err: any) => {
            store.publishSender = null;
            console.log(`Error: ${err}`);
          });


          // fixes issue when clonned element follows content changes (https://www.gitmemory.com/issue/artf/grapesjs-mjml/234/786540674)
          const removeSymbols = (model: any) => {
            if (typeof model.attributes?.__symbol !== "undefined") {
              delete model.attributes.__symbol;
            }
          
            if (typeof model.attributes?.__symbols !== "undefined") {
              delete model.attributes.__symbols;
            }
          };
          editor.on('component:add', removeSymbols);
          editor.on('component:update', removeSymbols);

          editor.on('block:add', function () {
            // console.log(`BLOCK ADD`);
            // console.log(arguments);
          });

          editor.on('component:add', function () {
            // console.log(`ADD`);
            // console.log(arguments);
          });

          editor.on('component:mount', function (component: any) {
            // console.log(`MOUNT`);
            // console.log(arguments);

            const componentType = editor.DomComponents.getType(component.attributes.type);
            const constructorVersion = componentType?.model.prototype.defaults.attributes.version;
            const pageComponentVersion = component.attributes.version;
            const cancelBlockUpdate = component.attributes.attributes["universal-block-not-update"];

            // if version defined and newer version in constructor
            if (constructorVersion && (!pageComponentVersion || constructorVersion > pageComponentVersion) && (typeof cancelBlockUpdate === "undefined" || cancelBlockUpdate === false)) {
              // update component's content and it's version
              component.components(componentType.model.prototype.defaults.components);
              component.attributes.version = constructorVersion;
              updatedComponents.push(component.attributes.type);
            }
          });
          
          const panelManager = editor.Panels;

          /* Hide trash button */
          panelManager.removeButton('options', 'canvas-clear');

          /* Publish page  functionality */
          panelManager.addButton('options',
            [{
              id: "publish-btn",
              className: "fa fa-paper-plane",
              command: 'publish-page',
              attributes: {title: "Publish"}
            }]
          );

          editor.Commands.add('publish-page', { 
            run: function(editor: any, sender: any) {
              store.publishSender = sender;
              editor.runCommand(saveDBCommand);
            }
          });

          // customize device resolutions (desktop, tablet, mobile)
          const deviceManager = editor.DeviceManager;
          deviceManager.get('Tablet').set({ widthMedia: '1299px', priotiy: 1299 });
          deviceManager.get('Mobile portrait').set({ widthMedia: '767px', priotiy: 767 });

        }}
      />
    </div>
  );
}

export default App;
