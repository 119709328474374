import grapesjs from 'grapesjs';
import initComponents from './components';
import initUniversalComponents from './universal-components';
import careerumComponents from './components-careerum';
import loadBlocks from './blocks-careerum';


export default grapesjs.plugins.add('gjs-blocks-careerum2.0', (editor: any, opts: any = {}) => {
  const config = {
    category: 'Careerum 2.0',
    ...opts
  };

  const domc = editor.DomComponents;

  // Add components
  const addTypeFromClassName = initComponents(editor, config);
  addTypeFromClassName(careerumComponents);

  initUniversalComponents(editor, config);

  // Add blocks
  loadBlocks(editor, config);


  // Define new Trait type - getcourse group
  const getcourseGroupType = 'getcourse-group';
  editor.TraitManager.addType(getcourseGroupType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(getcourseGroupType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[getcourseGroupType] = newVal;
    }
  });

  // Define new Trait type - link onclick
  const onclickLinkType = 'onclick';
  editor.TraitManager.addType(onclickLinkType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(onclickLinkType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[onclickLinkType] = newVal;
    }
  });

  const onclickLinkComponents = [
		'link',
	];
  let defaultAttributesOnclockLink: any = { 
    required: false
  };
  defaultAttributesOnclockLink[onclickLinkType] = '';

  onclickLinkComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Onclick',
              name: onclickLinkType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesOnclockLink
        }
      }
  
    })
  });

  // Define new Trait type - getcourse redirect URL
  const getcourseRedirectURLType = 'getcourse-redirect-url';
  editor.TraitManager.addType(getcourseRedirectURLType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(getcourseRedirectURLType, newVal);
      // save new value of redirect url to attributes
      this.target.attributes.attributes[getcourseRedirectURLType] = newVal;
    }
  });

  const careerRequestComponents = [
		'career-request',
		'career-request-tablet',
		'career-request-mobile',
		'careerum-getcourse-email-form',
		'careerum-getcourse-email-form-tablet',
		'careerum-getcourse-email-form-mobile',
    'careerum-veb-find-work',
		'careerum-veb-find-work-tablet',
		'careerum-veb-find-work-mobile',
    'careerum-veb-main',
		'careerum-veb-main-tablet',
		'careerum-veb-main-mobile',
    'careerum-tips-subscribe',
		'careerum-tips-subscribe-tablet',
		'careerum-tips-subscribe-mobile',
    'careerum-getcourse-email-form-2-fields',
		'careerum-getcourse-email-form-2-fields-tablet',
		'careerum-getcourse-email-form-2-fields-mobile',
    'careerum-getcourse-email-form-3-fields',
		'careerum-getcourse-email-form-3-fields-tablet',
		'careerum-getcourse-email-form-3-fields-mobile',
    'careerum-getcourse-email-form-4-fields',
		'careerum-getcourse-email-form-4-fields-tablet',
		'careerum-getcourse-email-form-4-fields-mobile',
    'careerum-lead-form-row',
		'careerum-lead-form-row-tablet',
		'careerum-lead-form-row-mobile',
    'careerum-lead-form-sheets-row',
		'careerum-lead-form-sheets-row-tablet',
		'careerum-lead-form-sheets-row-mobile',
    'careerum-popup',
    'careerum-vorkr-form',
    'careerum-vorkr-form-tablet',
    'careerum-vorkr-form-mobile',
    'careerum-club2-popup-block-subscribe',
    'careerum-club30-subscribe',
    'careerum-business-form',
    'careerum-business-form-tablet',
    'careerum-business-form-mobile',
    'careerum-case2-content-block',
    'careerum-case2-content-block-tablet',
    'careerum-case2-content-block-mobile',
    'careerum-veb21-main',
    'careerum-veb21-main-tablet',
    'careerum-veb21-main-mobile',
    'careerum-veb22-form',
    'careerum-veb22-form-tablet',
    'careerum-veb22-form-mobile',
    'careerum-en-bootcamp-newsletter',
    'careerum-en-bootcamp-newsletter-tablet',
    'careerum-en-bootcamp-newsletter-mobile',
    'careerum-veb30-main',
    'careerum-veb30-main-tablet',
    'careerum-veb30-main-mobile',
    'careerum-veb30-main2',
    'careerum-veb30-main2-tablet',
    'careerum-veb30-main2-mobile',
    'careerum-veb30-form-block',
    'careerum-veb30-form-block-tablet',
    'careerum-veb30-form-block-mobile',
	];
  let defaultAttributes: any = { 
    required: false
  };
  defaultAttributes[getcourseGroupType] = '';
  defaultAttributes[getcourseRedirectURLType] = '';

  careerRequestComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Группа в GetCourse для лидов',
              name: getcourseGroupType,
              type: getcourseGroupType,
              changeProp: 1,
            },
            {
              label: 'Редирект после добавления в группу GetCourse',
              name: getcourseRedirectURLType,
              type: getcourseRedirectURLType,
              changeProp: 1,
            },
          ],
          attributes: defaultAttributes
        }
      }
  
    })
  });


  // Define new Trait type - timer
  const timerType = 'data-timer';
  editor.TraitManager.addType(timerType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(timerType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[timerType] = newVal;
    }
  });

  const timerComponents = [
		'careerum-interblock-timer',
    'careerum-interblock-timer-tablet',
    'careerum-interblock-timer-mobile',
		'careerum-club21-banner-top2',
    'careerum-club21-banner-top2-tablet',
    'careerum-club21-banner-top2-mobile',
		'careerum-club21-banner-top3',
    'careerum-club21-banner-top3-tablet',
    'careerum-club21-banner-top3-mobile',
    'careerum-veb22-main',
    'careerum-veb22-main-tablet',
    'careerum-veb22-main-mobile',
	];
  let defaultAttributesTimer: any = { 
    required: false
  };
  defaultAttributesTimer[timerType] = '';

  timerComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Статичная дата',
              name: 'data-static',
              type: 'checkbox',
              valueTrue: 'yes', 
              valueFalse: 'no',
            },
            {
              label: 'Дата и время окончания отсчета (08/16/2022 10:00 GMT+0300)',
              name: 'data-timer-end',
              type: 'text',
            },
            {
              label: 'Кол-во часов (для нестатичной даты)',
              name: 'data-offset',
              type: 'text',
            },
            {
              label: 'Редирект по окончании',
              name: 'data-redirect',
              type: 'checkbox',
              valueTrue: 'yes', 
              valueFalse: 'no',
            },
            {
              label: 'URL редиректа',
              name: 'data-url',
              type: 'text',
            }
          ],
          attributes: defaultAttributesTimer
        }
      }
  
    })
  });


  // Define new Trait type - cons tarifs
  const consultTariffsType = 'data-cons-link';
  editor.TraitManager.addType(consultTariffsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(consultTariffsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[consultTariffsType] = newVal;
    }
  });

  const consultTariffsComponents = [
		'careerum-cons-tariff-item',
	];
  let defaultAttributesConsult: any = { 
    required: false
  };
  defaultAttributesConsult[consultTariffsType] = '';

  consultTariffsComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена (€)',
              name: 'data-eur-amount',
              type: 'text',
            },
            {
              label: 'Цена (₽)',
              name: 'data-rub-amount',
              type: 'text',
            },
            {
              label: 'URL оплаты',
              name: 'data-link',
              type: 'text',
            }
          ],
          attributes: defaultAttributesConsult
        }
      }
  
    })
  });

  // Define new Trait type - Popup settings
  const popupType = 'data-popup';
  editor.TraitManager.addType(popupType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(popupType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[popupType] = newVal;
    }
  });

  const popupComponents = [
		'careerum-popup',
	];
  let defaultAttributesPopup: any = { 
    required: false
  };
  defaultAttributesPopup[popupType] = '';

  popupComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Таймаут (сек)',
              name: 'data-timeout',
              type: 'text',
            },
            {
              label: 'Открывать при выходе',
              name: 'data-exit',
              type: 'checkbox',
              valueTrue: 'yes',
              valueFalse: 'no',
            },
            {
              label: 'S - Открывать при скролле',
              name: 'data-scroll',
              type: 'checkbox',
              valueTrue: 'yes',
              valueFalse: 'no',
            },
            {
              label: 'S - Элемент для мониторинга (класс без точки)',
              name: 'data-element-classname',
              type: 'text',
            },
          ],
          attributes: defaultAttributesPopup
        }
      }
  
    })
  });


  
  // Define new Trait type - club2 tariffs currency switcher
  const club2TariffsType = 'data-tariff-switcher';
  editor.TraitManager.addType(club2TariffsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(club2TariffsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[club2TariffsType] = newVal;
    }
  });

  const club2TariffsComponents = [
		'club2-tariffs-item',
		'club21-tariffs-item',
	];
  let defaultAttributesClub2Tariff: any = { 
    required: false
  };
  defaultAttributesClub2Tariff[club2TariffsType] = '';

  club2TariffsComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена (от €)',
              name: 'data-price-eur',
              type: 'text',
            },
            {
              label: 'Цена (от ₽)',
              name: 'data-price-rub',
              type: 'text',
            },
            {
              label: 'URL оплаты EUR',
              name: 'data-link-eur',
              type: 'text',
            },
            {
              label: 'URL оплаты RUB',
              name: 'data-link-rub',
              type: 'text',
            },
            {
              label: 'Цена old (€)',
              name: 'data-price-old-eur',
              type: 'text',
            },
            {
              label: 'Цена old (₽)',
              name: 'data-price-old-rub',
              type: 'text',
            }
          ],
          attributes: defaultAttributesClub2Tariff
        }
      }
  
    })
  });

    // Define new Trait type - club2 period switcher
    const club2PeriodType = 'data-period-offer-id';
    editor.TraitManager.addType(club2PeriodType, {
      /**
       * Triggered when the value of the model is changed
       */
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(club2PeriodType, newVal);
        // save new value of group to attributes
        this.target.attributes.attributes[club2PeriodType] = newVal;
      }
    });
  
    const club2PeriodsComponents = [
      'careerum-club2-popup__period-switcher',
    ];
    let defaultAttributesClub2Period: any = { 
      required: false
    };
    defaultAttributesClub2Period[club2PeriodType] = '';
  
    club2PeriodsComponents.forEach((component) => {
      domc.addType(component, {
  
        model: {
          defaults: {
    
            traits: [
              ...domc.getType(component).model.prototype.defaults.traits,
              {
                label: 'ID оффера',
                name: 'data-period-offer-id',
                type: 'text',
              }
            ],
            attributes: defaultAttributesClub2Period
          }
        }
    
      })
    });


    // Define new Trait type - youtube video id
    const ytVideoType = 'data-src';
    editor.TraitManager.addType(ytVideoType, {
      /**
       * Triggered when the value of the model is changed
       */
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(timerSwitcherType, newVal);
        // save new value of group to attributes
        this.target.attributes.attributes[timerSwitcherType] = newVal;
      }
    });
  
    const ytVideoComponents = [
      'careerum-veb22-main',
      'careerum-veb22-main-tablet',
      'careerum-veb22-main-mobile',
    ];
    let defaultAttributesYtVideo: any = { 
      required: false
    };
    defaultAttributesYtVideo[ytVideoType] = '';
  
    ytVideoComponents.forEach((component) => {
      domc.addType(component, {
  
        model: {
          defaults: {
    
            traits: [
              ...domc.getType(component).model.prototype.defaults.traits,
              {
                label: 'Youtube video ID',
                name: ytVideoType,
                type: 'text',
              }
            ],
            attributes: defaultAttributesYtVideo
          }
        }
    
      })
    });

    
    // Define new Trait type - banner timer switcher
    const timerSwitcherType = 'data-timer';
    editor.TraitManager.addType(timerSwitcherType, {
      /**
       * Triggered when the value of the model is changed
       */
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(timerSwitcherType, newVal);
        // save new value of group to attributes
        this.target.attributes.attributes[timerSwitcherType] = newVal;
      }
    });
  
    const timerSwitcherComponents = [
      'careerum-club21-banner-top2',
      'careerum-club21-banner-top2-tablet',
      'careerum-club21-banner-top2-mobile',
      'careerum-club21-banner-top3-mobile'
    ];
    let defaultAttributesTimerSwitcher: any = { 
      required: false
    };
    defaultAttributesTimerSwitcher[timerSwitcherType] = '';
  
    timerSwitcherComponents.forEach((component) => {
      domc.addType(component, {
  
        model: {
          defaults: {
    
            traits: [
              ...domc.getType(component).model.prototype.defaults.traits,
              {
                label: 'Timer ON',
                name: timerSwitcherType,
                type: 'checkbox',
                valueTrue: 'yes',
                valueFalse: 'no',
              }
            ],
            attributes: defaultAttributesTimerSwitcher
          }
        }
    
      })
    });

        
    // Define new Trait type - theme switcher
    const themeSwitcherType = 'data-theme';
    editor.TraitManager.addType(themeSwitcherType, {
      /**
       * Triggered when the value of the model is changed
       */
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(themeSwitcherType, newVal);
        // save new value of group to attributes
        this.target.attributes.attributes[themeSwitcherType] = newVal;
      }
    });
  
    const themeSwitcherComponents = [
      'careerum-club21-banner-top3',
      'careerum-club21-banner-top3-tablet',
      'careerum-club21-banner-top3-mobile',
    ];
    let defaultAttributesThemeSwitcher: any = { 
      required: false
    };
    defaultAttributesThemeSwitcher[themeSwitcherType] = '';
  
    themeSwitcherComponents.forEach((component) => {
      domc.addType(component, {
  
        model: {
          defaults: {
    
            traits: [
              ...domc.getType(component).model.prototype.defaults.traits,
              {
                label: 'Dark theme ON',
                name: themeSwitcherType,
                type: 'checkbox',
                valueTrue: 'dark',
                valueFalse: 'light',
              }
            ],
            attributes: defaultAttributesThemeSwitcher
          }
        }
    
      })
    });

    // Define new Trait type - veb30 club video ID
    const video3Type = 'data-video-id';
    editor.TraitManager.addType(video3Type, {
      /**
       * Triggered when the value of the model is changed
       */
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(video3Type, newVal);
        // save new value of group to attributes
        this.target.attributes.attributes[video3Type] = newVal;
      }
    });
  
    const video3Components = [	
      'careerum-veb30-speaker',
      'careerum-veb30-speaker-tablet',
    ];
    let defaultAttributesVideo3: any = {
      required: false
    };
    defaultAttributesVideo3[video3Type] = '';
  
    video3Components.forEach((component) => {
      domc.addType(component, {
  
        model: {
          defaults: {
  
            traits: [
              ...domc.getType(component).model.prototype.defaults.traits,
              {
                label: 'Vimeo Video ID',
                name: video3Type,
                type: 'text',
              }
            ],
            attributes: defaultAttributesVideo3
          }
        }
  
      })
    });
  
  // Define new Trait type - faq tabs
  const faqTabsType = 'data-tab-name';
  editor.TraitManager.addType(faqTabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(faqTabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[faqTabsType] = newVal;
    }
  });

  const faqComponents = [
		'careerum-faq2-tab',
	];
  let defaultAttributesFaq: any = { 
    required: false
  };
  defaultAttributesFaq[faqTabsType] = '';

  faqComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Название',
              name: faqTabsType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesFaq
        }
      }
  
    })
  });

  // Define new Trait type - data href for banner
  const hrefdataType = 'data-href';
  editor.TraitManager.addType(hrefdataType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(hrefdataType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[hrefdataType] = newVal;
    }
  });

  const hrefdataComponents = [
		'careerum-club21-banner-top',
	  'careerum-club21-banner-top-tablet',
	  'careerum-club21-banner-top-mobile',
		'careerum-club21-banner-top2',
    'careerum-club21-banner-top2-tablet',
    'careerum-club21-banner-top2-mobile',
		'careerum-club21-banner-top3',
    'careerum-club21-banner-top3-tablet',
    'careerum-club21-banner-top3-mobile',
	];
  let defaultAttributesHrefData: any = { 
    required: false
  };
  defaultAttributesHrefData[hrefdataType] = '';

  hrefdataComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Якорь на блок',
              name: hrefdataType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesHrefData
        }
      }
  
    })
  });
  
  // Define new Trait type - reasons tabs
  const reasonTabsType = 'data-image';
  editor.TraitManager.addType(reasonTabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(reasonTabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[reasonTabsType] = newVal;
    }
  });

  const reasonComponents = [
		'careerum-club30-reasons-accordeon-item',
	];
  let defaultAttributesReas: any = { 
    required: false
  };
  defaultAttributesReas[reasonTabsType] = '';

  reasonComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Ссылка на картинку',
              name: reasonTabsType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesReas
        }
      }
  
    })
  });
  
  // Define new Trait type - goals 3.0 tabs
  const goals30TabsType = 'data-image';
  editor.TraitManager.addType(goals30TabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(goals30TabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[goals30TabsType] = newVal;
    }
  });

  const goals30Components = [
		'careerum-club30-goals-tab',
	];
  let defaultAttributesGoals: any = { 
    required: false
  };
  defaultAttributesGoals[goals30TabsType] = '';

  goals30Components.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Название',
              name: 'data-name',
              type: 'text',
            },
            {
              label: 'Оценка (4,6/5)',
              name: 'data-val',
              type: 'text',
            }
          ],
          attributes: defaultAttributesGoals
        }
      }
  
    })
  });

  
  // Define new Trait type - program tabs
  const programTabsType = 'data-name';
  editor.TraitManager.addType(programTabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(programTabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[programTabsType] = newVal;
    }
  });

  const programComponents = [
		'club21-programs-item',
	];
  let defaultAttributesProgramm: any = { 
    required: false
  };
  defaultAttributesProgramm[programTabsType] = '';

  programComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Название',
              name: programTabsType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesProgramm
        }
      }
  
    })
  });
  
  
  // Define new Trait type - form popup after send
  const form4fieldPopupType = 'data-popup-open';
  editor.TraitManager.addType(form4fieldPopupType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(form4fieldPopupType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[form4fieldPopupType] = newVal;
    }
  });

  const form4fieldPopupComponents = [
		'careerum-getcourse-email-form-4-fields',
    'careerum-getcourse-email-form-4-fields-tablet',
    'careerum-getcourse-email-form-4-fields-mobile',
    'careerum-business-form',
    'careerum-business-form-tablet',
    'careerum-business-form-mobile',
    'careerum-case2-content-block',
    'careerum-case2-content-block-tablet',
    'careerum-case2-content-block-mobile',
    'careerum-veb21-main',
    'careerum-veb21-main-tablet',
    'careerum-veb21-main-mobile',
    'careerum-veb22-form',
    'careerum-veb22-form-tablet',
    'careerum-veb22-form-mobile',	
    'careerum-veb30-main',
    'careerum-veb30-main-tablet',
    'careerum-veb30-main-mobile',
    'careerum-veb30-main2',
    'careerum-veb30-main2-tablet',
    'careerum-veb30-main2-mobile',
    'careerum-veb30-form-block',
    'careerum-veb30-form-block-tablet',
    'careerum-veb30-form-block-mobile',
	];
  let defaultform4fieldPopupProgramm: any = { 
    required: false
  };
  defaultform4fieldPopupProgramm[form4fieldPopupType] = '';

  form4fieldPopupComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Попап после добавления в группу (id)',
              name: form4fieldPopupType,
              type: 'text',
            }
          ],
          attributes: defaultform4fieldPopupProgramm
        }
      }
  
    })
  });
  
  // Define new Trait type - consultations tabs
  const consTabsType = 'data-name';
  editor.TraitManager.addType(consTabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(consTabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[consTabsType] = newVal;
    }
  });

  const consultComponents = [
		'careerum-consultations-goals-tab',
	];
  let defaultAttributesConsultTab: any = { 
    required: false
  };
  defaultAttributesFaq[consTabsType] = '';

  consultComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Название',
              name: consTabsType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesConsultTab
        }
      }
  
    })
  });


  // Define new Trait type - google sheets list
  const googleSheetType = 'data-google-sheet';
  editor.TraitManager.addType(googleSheetType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(googleSheetType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[googleSheetType] = newVal;
    }
  });

  const googleSheetComponents = [
		'careerum-lead-form-sheets-row',
		'careerum-lead-form-sheets-row-tablet',
		'careerum-lead-form-sheets-row-mobile',

    'careerum-business-form',
    'careerum-business-form-tablet',
    'careerum-business-form-mobile',
    'careerum-veb22-form',
    'careerum-veb22-form-tablet',
    'careerum-veb22-form-mobile',
	];
  let defaultAttributesSheets: any = { 
    required: false
  };
  defaultAttributesSheets[googleSheetType] = '';

  googleSheetComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Id таблицы Google',
              name: googleSheetType,
              type: 'text',
            },
            {
              label: 'Лист таблицы Google',
              name: 'data-google-sheet-list',
              type: 'text',
            },
          ],
          attributes: defaultAttributesSheets
        }
      }
  
    })
  });


  
  // Define new Trait type - gc group waitlist
  const gcGroupWaitType = 'gc-group';
  editor.TraitManager.addType(gcGroupWaitType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(gcGroupWaitType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[gcGroupWaitType] = newVal;
    }
  });

  const gcGroupWaitComponents = [
		'popup-tariff__item',
	];
  let defaultAttributesgcGroupWait: any = { 
    required: false
  };
  defaultAttributesgcGroupWait[gcGroupWaitType] = '';

  gcGroupWaitComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Группа ГК',
              name: gcGroupWaitType,
              type: 'text',
            },
            {
              label: 'Тег Коммо',
              name: 'data-kommoAdditionalTags',
              type: 'text',
            }
          ],
          attributes: defaultAttributesgcGroupWait
        }
      }
  
    })
  });
  
  // Define new Trait type - faq tabs
  const consultTabsType = 'data-tab-name';
  editor.TraitManager.addType(consultTabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(consultTabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[consultTabsType] = newVal;
    }
  });

  const consultTabComponents = [
		'careerum-consult-tariff-tab',
	];
  let defaultAttributesConsTab: any = { 
    required: false
  };
  defaultAttributesConsTab[consultTabsType] = '';

  consultTabComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Название',
              name: consultTabsType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesConsTab
        }
      }
  
    })
  });
  
  // Define new Trait type - results tabs
  const resultsTabsType = 'data-tab-name';
  editor.TraitManager.addType(resultsTabsType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(resultsTabsType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[resultsTabsType] = newVal;
    }
  });

  const resultsComponents = [
		'careerum-results-tab',
	];
  let defaultAttributesRes: any = { 
    required: false
  };
  defaultAttributesRes[resultsTabsType] = '';

  resultsComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Название',
              name: resultsTabsType,
              type: 'text',
            }
          ],
          attributes: defaultAttributesRes
        }
      }
  
    })
  });

  // Define new Trait type - intensiv offer
  const intensOfferType = 'data-offer-link';
  editor.TraitManager.addType(intensOfferType, {
    /**
     * Triggered when the value of the model is changed
     */
    onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(intensOfferType, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[intensOfferType] = newVal;
    }
  });

  const intensOfferComponents = [
		'careerum-intens-form',
    'careerum-intens-form-tablet',
    'careerum-intens-form-mobile',
	];
  let defaultAttributesIntensOffer: any = { 
    required: false
  };
  defaultAttributesIntensOffer[intensOfferType] = '';

  intensOfferComponents.forEach((component) => {
    domc.addType(component, {

      model: {
        defaults: {
  
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            {
              label: 'Цена RUB',
              name: 'data-price-rub',
              type: 'text',
            },
            {
              label: 'Цена €',
              name: 'data-price-eur',
              type: 'text',
            },
            {
              label: 'Оффер RUB',
              name: 'data-offer-rub',
              type: 'text',
            },
            {
              label: 'Оффер €',
              name: 'data-offer-eur',
              type: 'text',
            },
            {
              label: 'Оферта RUB',
              name: 'data-offert-rub',
              type: 'text',
            },
            {
              label: 'Оферта €',
              name: 'data-offert-eur',
              type: 'text',
            }
          ],
          attributes: defaultAttributesIntensOffer
        }
      }
  
    })
  });



  // Define new Trait type for calendar dates
  let dateAttributes: any = { 
    required: false
  };

  const calendarTooltipCategory = 'data-tooltip-category';
  dateAttributes[calendarTooltipCategory] = '';
  editor.TraitManager.addType(calendarTooltipCategory, {
    // type: 'select',
    createInput: ( arg: any ) => {
      // Here we can decide to use properties from the trait
      const traitOpts = arg.trait.get('options') || [];
      const options = traitOpts.length ? traitOpts : [
        { name: "Обучение", value: "teach" },
        { name: "Нетворкинг", value: "network" },
        { name: "Welcome", value: "welcome" },
        { name: "Офлайн", value: "offline" },
      ];
      const curValue = arg.component.attributes[calendarTooltipCategory];
  
      // Create a new element container and add some content
      const el = document.createElement('div');
      el.classList.add("gjs-field-select");
      el.innerHTML = `
        <div>
          <select>
            <option ${!curValue ? 'selected': ''} value=""></option>
            ${options.map((opt: any) => `<option ${curValue == opt.value ? 'selected': ''} value="${opt.value}">${opt.name}</option>`).join('')}
          </select>
        </div>
        <div class="gjs-sel-arrow">
          <div class="gjs-d-s-arrow"></div>
        </div>
      `;
  
      // set handler
      const selectEl = el.querySelector('select');
      selectEl && selectEl.addEventListener('change', (ev: any) => {
        const newVal = ev.target.value;
        const component = arg.component;
        component.set(calendarTooltipCategory, newVal);
        // save new value of group to attributes
        component.attributes.attributes[calendarTooltipCategory] = newVal;
        component.view.el.setAttribute(calendarTooltipCategory, newVal);
      });
  
      return el;
    },
  });

  const calendarTooltipName = 'data-tooltip-name';
  dateAttributes[calendarTooltipName] = '';
  editor.TraitManager.addType(calendarTooltipName, {
    /**
     * Triggered when the value of the model is changed
     */
     onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(calendarTooltipName, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[calendarTooltipName] = newVal;
      this.target.view.el.setAttribute(calendarTooltipName, newVal);
    }
  });

  const calendarTooltipTime = 'data-tooltip-time';
  dateAttributes[calendarTooltipTime] = '';
  editor.TraitManager.addType(calendarTooltipTime, {
    /**
     * Triggered when the value of the model is changed
     */
     onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(calendarTooltipTime, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[calendarTooltipTime] = newVal;
      this.target.view.el.setAttribute(calendarTooltipTime, newVal);
    }
  });

  const calendarTooltipHeader = 'data-tooltip-header';
  dateAttributes[calendarTooltipHeader] = '';
  editor.TraitManager.addType(calendarTooltipHeader, {
    /**
     * Triggered when the value of the model is changed
     */
     onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(calendarTooltipHeader, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[calendarTooltipHeader] = newVal;
      this.target.view.el.setAttribute(calendarTooltipHeader, newVal);
    }
  });

  const calendarTooltipText = 'data-tooltip-text';
  dateAttributes[calendarTooltipText] = '';
  editor.TraitManager.addType(calendarTooltipText, {
    /**
     * Triggered when the value of the model is changed
     */
     onValueChange: function () {
      const newVal = this.model.get('value');
      this.target.set(calendarTooltipText, newVal);
      // save new value of group to attributes
      this.target.attributes.attributes[calendarTooltipText] = newVal;
      this.target.view.el.setAttribute(calendarTooltipText, newVal);
    }
  });

  // add component for calendar dates with new traits
  const componentName = 'calendar-date';
  domc.addType(componentName, {
    model: {
      defaults: {
        traits: [
          {
            label: "Категория попапа",
            name: calendarTooltipCategory,
            type: calendarTooltipCategory,
            changeProp: 1,
          },
          {
            label: "Тип ивента в попапе",
            name: calendarTooltipName,
            type: calendarTooltipName,
            changeProp: 1,
          },
          {
            label: "Время для попапа",
            name: calendarTooltipTime,
            type: calendarTooltipTime,
            changeProp: 1,
          },
          {
            label: "Метка попапа",
            name: calendarTooltipHeader,
            type: calendarTooltipHeader,
            changeProp: 1,
          },
          {
            label: "Текст попапа",
            name: calendarTooltipText,
            type: calendarTooltipText,
            changeProp: 1,
          },
        ],
        attributes: dateAttributes
      }
    },
    isComponent: (el: HTMLElement) => {
      if(el.tagName === 'DIV' && el.classList.contains(componentName)){
        return {type: componentName};
      }
    },
  });



  // Define new Trait type - subscription params
  const tariffPageComponents = [
		'careerum-tariff-page',
		'careerum-tariff-page-tablet',
		'careerum-tariff-page-mobile',
	];
  let defaultAttrs: any = {};
  let additionalTraits: any[] = [];

  const attributeTypes = [
    {
      type: 'priceRubFor1Month',
      attrName: 'data-price-1-month-rub',
      defaultValue: '8 900 ₽',
      label: 'Цена (RUB) за 1 месяц',
    },
    {
      type: 'crossedPriceRubFor1Month',
      attrName: 'data-crossed-price-1-month-rub',
      defaultValue: '',
      label: 'Зачёркнутая цена (RUB) за 1 месяц',
    },    
    {
      type: 'offerRubFor1Month',
      attrName: 'data-offer-1-month-rub',
      defaultValue: '28',
      label: 'Предложение (RUB) за 1 месяц',
    },
    {
      type: 'priceRubFor3Month',
      attrName: 'data-price-3-months-rub',
      defaultValue: '24 900 ₽',
      label: 'Цена (RUB) за 3 месяца',
    },
    {
      type: 'crossedPriceRubFor3Month',
      attrName: 'data-crossed-price-3-months-rub',
      defaultValue: '26 700 ₽',
      label: 'Зачёркнутая цена (RUB) за 3 месяца',
    },    
    {
      type: 'perMonthPriceRubFor3Month',
      attrName: 'data-per-month-price-3-months-rub',
      defaultValue: '(8 300 ₽/месяц)',
      label: 'Ежемесячная цена (RUB) за 3 месяца',
    },    
    {
      type: 'offerRubFor3Month',
      attrName: 'data-offer-3-months-rub',
      defaultValue: '29',
      label: 'Предложение (RUB) за 3 месяца',
    },
    {
      type: 'priceRubFor6Month',
      attrName: 'data-price-6-months-rub',
      defaultValue: '48 900 ₽',
      label: 'Цена (RUB) за 6 месяцев',
    },
    {
      type: 'crossedPriceRubFor6Month',
      attrName: 'data-crossed-price-6-months-rub',
      defaultValue: '53 400 ₽',
      label: 'Зачёркнутая цена (RUB) за 6 месяцев',
    },
    {
      type: 'perMonthPriceRubFor6Month',
      attrName: 'data-per-month-price-6-months-rub',
      defaultValue: '(8 150 ₽/месяц)',
      label: 'Ежемесячная цена (RUB) за 6 месяцев',
    }, 
    {
      type: 'offerRubFor6Month',
      attrName: 'data-offer-6-months-rub',
      defaultValue: '30',
      label: 'Предложение (RUB) за 6 месяцев',
    },
    {
      type: 'priceRubFor12Month',
      attrName: 'data-price-12-months-rub',
      defaultValue: '11 900 ₽',
      label: 'Цена (RUB) за 12 месяцев',
    },
    {
      type: 'crossedPriceRubFor12Month',
      attrName: 'data-crossed-price-12-months-rub',
      defaultValue: '106 800 ₽',
      label: 'Зачёркнутая цена (RUB) за 12 месяцев',
    },
    {
      type: 'perMonthPriceRubFor12Month',
      attrName: 'data-per-month-price-12-months-rub',
      defaultValue: '(8 050 ₽/месяц)',
      label: 'Ежемесячная цена (RUB) за 12 месяцев',
    }, 
    {
      type: 'offerRubFor12Month',
      attrName: 'data-offer-12-months-rub',
      defaultValue: '31',
      label: 'Предложение (RUB) за 12 месяцев',
    },

    {
      type: 'priceEurFor1Month',
      attrName: 'data-price-1-month-eur',
      defaultValue: '900 €',
      label: 'Цена (EUR) за 1 месяц',
    },
    {
      type: 'crossedPriceEurFor1Month',
      attrName: 'data-crossed-price-1-month-eur',
      defaultValue: '',
      label: 'Зачёркнутая цена (EUR) за 1 месяц',
    },
    {
      type: 'offerEurFor1Month',
      attrName: 'data-offer-1-month-eur',
      defaultValue: '49',
      label: 'Предложение (EUR) за 1 месяц',
    },
    {
      type: 'priceEurFor3Month',
      attrName: 'data-price-3-months-eur',
      defaultValue: '1 000 €',
      label: 'Цена (EUR) за 3 месяца',
    },
    {
      type: 'crossedPriceEurFor3Month',
      attrName: 'data-crossed-price-3-months-eur',
      defaultValue: '327 €',
      label: 'Зачёркнутая цена (EUR) за 3 месяца',
    },
    {
      type: 'perMonthPriceEurFor3Month',
      attrName: 'data-per-month-price-3-months-eur',
      defaultValue: '(103 €/месяц)',
      label: 'Ежемесячная цена (EUR) за 3 месяца',
    },  
    {
      type: 'offerEurFor3Month',
      attrName: 'data-offer-3-months-eur',
      defaultValue: '50',
      label: 'Предложение (EUR) за 3 месяца',
    },
    {
      type: 'priceEurFor6Month',
      attrName: 'data-price-6-months-eur',
      defaultValue: '1 100 €',
      label: 'Цена (EUR) за 6 месяцев',
    },
    {
      type: 'crossedPriceEurFor6Month',
      attrName: 'data-crossed-price-6-months-eur',
      defaultValue: '654 €',
      label: 'Зачёркнутая цена (EUR) за 6 месяцев',
    },
    {
      type: 'perMonthPriceEurFor6Month',
      attrName: 'data-per-month-price-6-months-eur',
      defaultValue: '(100 €/месяц)',
      label: 'Ежемесячная цена (EUR) за 6 месяцев',
    },
    {
      type: 'offerEurFor6Month',
      attrName: 'data-offer-6-months-eur',
      defaultValue: '51',
      label: 'Предложение (EUR) за 6 месяцев',
    },
    {
      type: 'priceEurFor12Month',
      attrName: 'data-price-12-months-eur',
      defaultValue: '1 200 €',
      label: 'Цена (EUR) за 12 месяцев',
    },
    {
      type: 'crossedPriceEurFor12Month',
      attrName: 'data-crossed-price-12-months-eur',
      defaultValue: '1 308 €',
      label: 'Зачёркнутая цена (EUR) за 12 месяцев',
    },
    {
      type: 'perMonthPriceEurFor12Month',
      attrName: 'data-per-month-price-12-months-eur',
      defaultValue: '(98 €/месяц)',
      label: 'Ежемесячная цена (EUR) за 12 месяцев',
    },
    {
      type: 'offerEurFor12Month',
      attrName: 'data-offer-12-months-eur',
      defaultValue: '52',
      label: 'Предложение (EUR) за 12 месяцев',
    },
    {
      type: 'googleSheetsId',
      attrName: 'data-google-sheets-id',
      defaultValue: '0',
      label: 'Id google sheets',
    },
  ];

  attributeTypes.forEach(type => {
    const typeName = type.type;
    defaultAttrs[typeName] = type.defaultValue;

    additionalTraits.push({
      label: type.label,
      name: typeName,
      type: typeName,
      changeProp: 1,
    });

    editor.TraitManager.addType(typeName, {
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(typeName, newVal);

        // save new value to attributes
        this.target.attributes.attributes[type.attrName] = newVal;
      }
    });
  });  

  tariffPageComponents.forEach((component) => {
    domc.addType(component, {
      model: {
        defaults: {
          ...defaultAttrs,
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            ...additionalTraits,
          ],
        }
      }
    })
  });

  
  // Define new Trait type - subscription params
  const reserveComponents = [
		'careerum-reserve-main',
		'careerum-reserve-main-tablet',
		'careerum-reserve-main-mobile',
	];
  let defaultAttrsForReserve: any = {};
  let additionalTraitsForReserve: any[] = [];

  const reserveTraitsTypes = [
    {
      type: 'getcourse-group-1point',
      attrName: 'getcourse-group-1point',
      defaultValue: '',
      label: 'Группа GetCourse 1 пункт',
    },
    {
      type: 'getcourse-group-2point',
      attrName: 'getcourse-group-2point',
      defaultValue: '',
      label: 'Группа GetCourse 2 пункт',
    },
    {
      type: 'getcourse-group-3point',
      attrName: 'getcourse-group-3point',
      defaultValue: '',
      label: 'Группа GetCourse 3 пункт',
    },
    {
      type: 'getcourse-group-4point',
      attrName: 'getcourse-group-4point',
      defaultValue: '',
      label: 'Группа GetCourse 4 пункт',
    },
    {
      type: 'yes-point-getcourse-group',
      attrName: 'yes-point-getcourse-group',
      defaultValue: '',
      label: 'Группа GetCourse, если да',
    },
    {
      type: 'yes-point-redirect-url',
      attrName: 'yes-point-redirect-url',
      defaultValue: '',
      label: 'Адрес редиректа, если да',
    },
    {
      type: 'no-point-getcourse-group',
      attrName: 'no-point-getcourse-group',
      defaultValue: '',
      label: 'Группа GetCourse, если нет',
    },
    {
      type: 'no-point-redirect-url',
      attrName: 'no-point-redirect-url',
      defaultValue: '',
      label: 'Адрес редиректа, если нет',
    },
  ];

  reserveTraitsTypes.forEach(type => {
    const typeName = type.type;
    defaultAttrsForReserve[typeName] = type.defaultValue;

    additionalTraitsForReserve.push({
      label: type.label,
      name: typeName,
      type: typeName,
      changeProp: 1,
    });

    editor.TraitManager.addType(typeName, {
      onValueChange: function () {
        const newVal = this.model.get('value');
        this.target.set(typeName, newVal);

        // save new value to attributes
        this.target.attributes.attributes[type.attrName] = newVal;
      }
    });
  });  

  reserveComponents.forEach((component) => {
    domc.addType(component, {
      model: {
        defaults: {
          ...defaultAttrsForReserve,
          traits: [
            ...domc.getType(component).model.prototype.defaults.traits,
            ...additionalTraitsForReserve,
          ],
        }
      }
    })
  });

});
