const classNames = [

	'careerum-en-header',
    'careerum-en-header-tablet',
    'careerum-en-header-mobile',

	'careerum-club40-main',
    'careerum-club40-main-tablet',
    'careerum-club40-main-mobile',

	'careerum-club40-main2',
	'careerum-club40-achievements',

	'careerum-club40-about',
    'careerum-club40-about-tablet',
    'careerum-club40-about-mobile',

	'careerum-club40-goals',
    'careerum-club40-goals-tablet',
    'careerum-club40-goals-mobile',

	'careerum-club40-clients',
    'careerum-club40-clients-tablet',
    'careerum-club40-clients-mobile',

	'careerum-club40-mission',
    'careerum-club40-mission-tablet',
    'careerum-club40-mission-mobile',

	'careerum-club40-author',
    'careerum-club40-author-tablet',
    'careerum-club40-author-mobile',

	'careerum-club40-time',
    'careerum-club40-time-tablet',
    'careerum-club40-time-mobile',

	'careerum-club40-tariffs',
    'careerum-club40-tariffs-tablet',
    'careerum-club40-tariffs-mobile',
	'club40-tariffs2',
    'club40-tariffs2-tablet',
    'club40-tariffs2-mobile',
    
	'careerum-club40-results',
	'careerum-club40-banner-top',
	'careerum-club40-goals-slider',
	'careerum-club40-schedule',

	'careerum-club40-support',
    'careerum-club40-support-tablet',
    'careerum-club40-support-mobile',
    
	'club40-support2',
    'club40-support2-tablet',
    'club40-support2-mobile',

	'careerum-club40-form',
    'careerum-club40-form-tablet',
    'careerum-club40-form-mobile',

	'careerum-club40-banner1',
    'careerum-club40-banner1-tablet',
    'careerum-club40-banner1-mobile',

	'careerum-course40-main',
    'careerum-course40-main-tablet',
    'careerum-course40-main-mobile',

	'careerum-course40-about',
    'careerum-course40-about-tablet',
    'careerum-course40-about-mobile',

	'careerum-course40-results',
    'careerum-course40-results-tablet',
    'careerum-course40-results-mobile',

	'careerum-course40-banner1',
    'careerum-course40-banner1-tablet',
    'careerum-course40-banner1-mobile',

	'careerum-course40-banner2',

	'careerum-course40-tariffs',
    'careerum-course40-tariffs-tablet',
    'careerum-course40-tariffs-mobile',


    'careerum-popup',
    'careerum-club40-popup-pay',
    'careerum-club40-popup-pay2',
    'careerum-club40-popup-lk',
    'careerum-club40-popup-subscribe',
    'careerum-club40-popup-subscribe2',
    'careerum-club40-popup-thanks',
	'no-cabinet',
	'has-cabinet',
    'club40-time-item',
    'club40-tariffs-item',
    'course40-tariffs-item',
    'club40-goals-form',
    'blackfriday-tale-change-currency',
    'trip-tale-change-currency',

    'careerum-blackfriday-main',
    'careerum-blackfriday-tales',
    'careerum-blackfriday-mini',
    'careerum-blackfriday-form',
    'careerum-blackfriday-banner',
    'careerum-blackfriday-popup',
    'careerum-gift',

    'careerum-veb4-main',
    'careerum-veb4-problems',
    'careerum-veb4-form',
    'careerum-veb4-reviews',
    'careerum-veb4-buy',

    'careerum-product-main',
    'careerum-product-for',
    'careerum-product-about',
    'careerum-product-form',
    'careerum-product-how',
    'careerum-product-results',
    'careerum-product-news',
    'careerum-product-additional',
    'careerum-product-reviews',
    'careerum-product-tariffs',
    
    'careerum-quiz-main',
    
    'careerum-consultations4-main',
    'careerum-consultations4-experts',
    'careerum-consultations4-tariffs',
    'consultations4-tariffs-item',
    'careerum-consultations4-about',
    'careerum-consultations4-how',
    'careerum-consultations4-results',
    
    'careerum-intens4-main',
    'careerum-intens4-for',
    'careerum-intens4-how',
    'careerum-intens4-banner',
    'careerum-intens4-study',
    'careerum-intens4-programm',
    'careerum-intens4-results',
    'careerum-intens4-cases',
    'careerum-intens4-subscribe',
    'careerum-intens4-boost',
    'careerum-intens4-photos',
    'careerum-intens4-quiz',

    'careerum-trip-main',
    'careerum-thanks4-main',
    
    'careerum-cv-main',
    'careerum-cv-who',
    'careerum-cv-why',
    'careerum-cv-power',
    'careerum-cv-format',
    'careerum-cv-schedule',
    'careerum-cv-experts-why',
    'careerum-cv-feedback',
    'careerum-cv-results',
    'careerum-cv-experts',
    'careerum-cv-header',
    'careerum-cv-clients',
    'careerum-cv-reviews',
    'careerum-cv-tariffs',
    'careerum-cv-pay',
    'careerum-cv-start',

    'careerum-affirm-main',
    'careerum-diagnostics-main',
];

export default classNames;