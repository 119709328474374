/// <reference types="./grapesjs" />

import React from 'react';
import GrapesJS from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';

import webpage from 'grapesjs-preset-webpage';
import "grapesjs-blocks-basic";
import "grapesjs-custom-code";
import "grapesjs-tooltip";
import "grapesjs-tabs";
import "grapesjs-lory-slider";
import "grapesjs-style-gradient";
import "grapesjs-style-filter";
import "grapesjs-style-bg";



const defaultEditorElementId = 'grapesjs-react-editor';

const presets: any = {
  webpage
};

export interface EditorProps {
  id?: string;

  presetType?: 'webpage';

  plugins?: string[];

  pluginsOpts?: any;

  children?: React.ReactElement<any> | Array<React.ReactElement<any>>;

  storageManager?: any;

  blockManager?: any;

  styleManager?: {};

  assetManager?: {};

  width?: string | number;

  height?: string | number;

  components?: object[];

  blocks?: object[];

  // CSS that could only be seen (for instance, inside the code viewer)
  protectedCss?: string;

  onInit?(editor: any): void;

  onDestroy?(editor: any): void;
}

interface EditorState {
  editor: any;
}

class GrapesJSEditor extends React.Component<EditorProps, EditorState> {
  public static defaultProps: EditorProps = {
    id: defaultEditorElementId,
    presetType: 'webpage',
    plugins: [],
    pluginsOpts: {},
    blocks: [],
    blockManager: {},
    storageManager: {},
    styleManager: {},
    assetManager: {},
    width: 'auto',
    height: '100vh',
    protectedCss: '* { box-sizing: border-box; } body {margin: 0;}',
    components: [],
  };

  public componentDidMount(): void {
    const {
      onInit,
      id,
      blockManager,
      styleManager,
      storageManager,
      assetManager,
      width,
      height,
      plugins: propPlugins,
      pluginsOpts: propPluginsOpts,
      protectedCss,
      presetType,
    } = this.props;

    const preset = presetType ? presets[presetType] : [];
    let pluginsProps = propPlugins ? propPlugins : [];
    pluginsProps = pluginsProps.concat(preset);

    const editor = GrapesJS.init({
      container: `#${id}`,
      fromElement: true,
      blockManager,
      styleManager,
      storageManager,
      assetManager,
      width,
      height,
      plugins: pluginsProps,
      pluginsOpts: propPluginsOpts,
      protectedCss
    });

    if (typeof onInit === 'function') {
      onInit(editor);
    }

    this.setState({
      editor,
    });
  }

  public componentWillUnmount(): void {
    const { editor } = this.state;
    const {
      onDestroy,
      id,
    } = this.props;
    if (editor) {
      if (typeof onDestroy === 'function') {
        onDestroy(editor);
      }
      GrapesJS.editors = GrapesJS.editors.filter((e: any) => e !== editor);
      editor.destroy();
      if (document) {
        const elementId = id ? id : defaultEditorElementId;
        const container: HTMLDivElement = document.getElementById(elementId) as HTMLDivElement;
        if (container) {
          container.innerHTML = '';
        }
      }
    }
  }

  public render() {
    const {
      children,
      id,
    } = this.props;

    return (
      <div id={id}>
        {children}
      </div>
    );
  }
}

export default GrapesJSEditor;
